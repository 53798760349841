<template>
    <v-main>
      <v-container fill-height="fill-height">
        <v-layout align-center="align-center" justify-center="justify-center">
          <v-flex class="login-form text-xs-center text-center"> 
            <div class="display-1 mb-3">
              Festit
            </div>
            <v-card light="light">
              <v-card-title>Área Administrativa</v-card-title>
              <v-card-text>
                <v-form ref="form">
                    <v-text-field
                    prepend-icon="mdi-email"
                    v-model="email"
                    outline
                    label="Email"
                    :rules="emailRules"
                    outlined
                    required
                    @keyup="keymonitor"
                  ></v-text-field>
                  <v-text-field
                    v-model="password"
                    outline
                    label="Senha"
                    prepend-icon="mdi-lock"
                    :append-icon="
                      showPassword ? 'mdi-visibility' : 'mdi-visibility_off'
                    "
                    :type="showPassword ? 'text' : 'password'"
                    :counter="20"
                    :rules="passwordRules"
                    outlined
                    required
                    @click:append="showPassword = !showPassword"
                    @keyup="keymonitor"
                  ></v-text-field>
                  <v-btn dark @click="validate" block="block">ENTRAR</v-btn>
                </v-form>
              </v-card-text>
            </v-card>
            <v-dialog
                v-model="dialogForgotPassword"
                persistent
                max-width="600px"
            >
                <template v-slot:activator="{ on }">
                    <a class="w-100 btn-senha mt-3" v-on="on"
                        >Esqueceu a Senha?
                    </a>
                </template>
                <v-card>
                <v-card-title>
                    <span class="headline">Esqueci a senha</span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                    <v-row no-gutters>
                        <v-col cols="12" md="12">
                        <v-form ref="formForgot">
                            <v-text-field
                            v-model="email"
                            :rules="emailRules"
                            label="Email*"
                            hint="meuemail@email.com"
                            persistent-hint
                            required
                            ></v-text-field>
                        </v-form>
                        </v-col>
                    </v-row>
                    </v-container>
                    <small>*indica campo obrigatório</small>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                    color="blue darken-1"
                    text
                    @click="dialogForgotPassword = false"
                    >Cancelar</v-btn
                    >
                    <v-btn
                    color="blue darken-1"
                    text
                    @click="recoveryPassword()"
                    >Enviar</v-btn
                    >
                </v-card-actions>
                </v-card>
            </v-dialog>
          </v-flex>
        </v-layout>
      </v-container>

    <v-snackbar v-model="showSnackbar" :timeout="6000" :top="true">
        {{snackbarMessage}}
        <v-btn dark text @click="showSnackbar = false">
        Fechar
        </v-btn>
    </v-snackbar>

    <v-snackbar v-model="showSnackbarForgot" :timeout="6000" :top="true">
        Operação falhou!
        <v-btn dark text @click="showSnackbar = false">
        Fechar
        </v-btn>
    </v-snackbar>

    <v-dialog v-model="dialogSuccessForgot" hide-overlay width="300">
        <v-card class="mx-auto" color="success" dark>
        <v-card-title>
            <span class="title font-weight-light">Operação bem sucedida</span>
        </v-card-title>
        <v-card-text class="headline font-weight-bold">
            Verifique sua caixa de entrada!
        </v-card-text>
        </v-card>
    </v-dialog>

    <v-dialog v-model="dialogProgress" hide-overlay persistent width="300">
        <v-card color="primary" dark>
        <v-card-text>
            Realizando a operação por favor aguarde...
            <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
            ></v-progress-linear>
        </v-card-text>
        </v-card>
    </v-dialog>
  </v-main>
</template>

<script>
import database from "@/services/database";

export default {
  data() {
    return {
      showPassword: false,
      showSnackbar: false,
      dialogProgress: false,
      email: "",
      emailRules: [
        (v) => !!v || "E-mail é obrigatório",
        (v) => /.+@.+/.test(v) || "E-mail deve ser válido",
      ],
      password: "",
      passwordRules: [
        (v) => !!v || "Senha é obrigatório",
        (v) =>
          (v && v.length > 4 && v.length <= 20) ||
          "Senha deve ter no mínimo 4 e no máximo 20 caracteres",
      ],
      showSnackbarForgot: false,
      snackbarMessage: "",
      dialogForgotPassword: false,
      dialogSuccessForgot: false,
    };
  },
  methods: {
    keymonitor: function(event) {
      if (event.key == "Enter") {
        this.validate();
      }
    },
    validate: async function() {
      if (this.$refs.form.validate()) {
        this.dialogProgress = true;
        let result = await database.signIn(this.email, this.password);
        if (result.message) {
          this.dialogProgress = false;
          this.snackbarMessage = result.message;
          this.showSnackbar = true;
        } else {
          await database.getAllCategorias();
          this.$router.push({ path: "/sistema/visao-geral/" });
        }
      }
    },
    recoveryPassword: async function() {
      if (this.$refs.formForgot.validate()) {
        this.dialogProgress = true;
        let result = await database.recoveryPassword(this.email);

        if (result) {
          this.dialogProgress = false;
          this.dialogForgotPassword = false;
          this.dialogSuccessForgot = true;
        } else {
          this.dialogProgress = false;
          this.showSnackbarForgot = true;
        }
      }
    },
  },
};
</script>

<style scoped>
.login-form {
  max-width: 500px
}
.btn-senha {
  color: #fff;
}

</style>